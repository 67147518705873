import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import reduceBy from "ramda/src/reduce";
import sort from "ramda/src/sort";
import styled from "@emotion/styled";
import uniq from "ramda/src/uniq";

import BlogPost from "../../components/BlogPost";
import Layout from "../../layouts";

const BlogWrapper = styled.div`
  > article {
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    > *:not(a) {
      display: inline-block;
      margin-right: 1rem;
    }
  }
`;

class BlogIndex extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.tags = compose(
      sort((a, b) => a - b),
      uniq,
      reduceBy((tags, { node: post }) => tags.concat(...post.tags), [])
    )(this.props.data.allMarkdownRemark.edges);
  }

  render() {
    const allPosts = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout title="Blog - Angel Piscola">
        <BlogWrapper>
          {allPosts
            .sort(({ node: post }) => !post.frontmatter.written)
            .map(({ node: post }) => (
              <BlogPost data={post} key={post.frontmatter.slug} />
            ))}
        </BlogWrapper>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___written] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            written(formatString: "MMMM DD, YYYY")
            slug
            tags
          }
        }
      }
    }
  }
`;
